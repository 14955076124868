import React, { Component } from "react";
import "./App.scss";

class App extends Component {
  render() {
    return (
      <div class="ascii-animation">
        <div class="ascii-animation-inner">{`
                    /~___\\     |   |                                                   /~___\\     |   |                                                   /~___\\     |   |                                                   /~___\\     |   |                                                   /~___\\     |:  * #  '                                              /~___\\   ' |   *    #                          
                   /_/o o|     |   |                                                  /_/o o|     |   |                                                  /_/o o|     |   |                                                  /_/o o|     |  .*  '                                               /_/- -|     |  ***                                                 /_/- -|     |  ***    '                         
                    _\\ -/_     |   |        -----                                      _\\ -/_     |   |   -----                                           _\\ -/_     |   *--                                                 _\\ -/_     |  ***                                                  _\\ -/_     | *****                                                 _\\ o/_     ' *****                             
                   /  \\ / \\    |   |                                                  /  \\ / \\    |   |                                                  /  \\ / \\    |   |                                                  /  \\ / \\    |   *                                                  /  \\ / \\    |# ***  .                                              /  \\ / \\    |  ***       '                      
                  //__ __|\\\\   |   |                                                 //__ __|\\\\   |   |                                                 //__ __|\\\\   |   |                                                 //__ __|\\\\   |   |  '                        ----                  //__ __*\\\\   |   * #                    -----                      //__ _***\\   #   *                 -----         
                  \\--([==o \\\\  |   |               -----                             \\--([==o \\\\  |   |                    -----                        \\--([==o \\\\  |   |                         -----                   \\--([==* --  |   |                              -                  \\--([=***\\\\----- |    '                                            \\--([*****\\  | .-----                            
                    |    |  \\\\ |   |                                                   |    |  \\\\ |   |                                                   |    |  \\\\ |   |                                                   |    |  \\\\ |   |                                                   |    *  \\\\ |   |                                                   |   *** \\\\ |   |    #                          
                    |====|   ')|   |                                                   |====|   ')|   |                                                   |====|   ')|   |                                                   |====|   ')|   |                                                   |====|   ')|   |                                                   |====|   ')| . |       '                       
                    | || |     |   |                                                   | || |     |   |                                                   | || |     |   |                                                   | || |     |   |                                                   | || |     |   |                                                   | || |     |   |                               
____________________( )( )_____|   |                               ____________________( )( )_____|   |                               ____________________( )( )_____|   |                               ____________________( )( )_____|   |                               ____________________( )( )_____|   |                               ____________________( )( )_____|   |                               
                    | || |      \\  |                                                   | || |      \\  |                                                   | || |      \\  |                                                   | || |      \\  |                                                   | || |      \\  |                                                   | || |      \\  |                               
                    |_||_|       \\ |                                                   |_||_|       \\ |                                                   |_||_|       \\ |                                                   |_||_|       \\ |                                                   |_||_|       \\ |                                                   |_||_|       \\ |                               
                    [_][__\\       \\|_______________________________                    [_][__\\       \\|_______________________________                    [_][__\\       \\|_______________________________                    [_][__\\       \\|_______________________________                    [_][__\\       \\|_______________________________                    [_][__\\       \\|_______________________________

`}</div>
      </div>
    );
  }
}

export default App;
